import { env } from "$env/dynamic/public";
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'
import { replayIntegration } from '@sentry/browser'

const onError = init(
    env.PUBLIC_SENTRY_DSN,
    {
        sentryOptions: {
            integrations: [replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                networkCaptureBodies: true,
                networkDetailAllowUrls: [/.*/],
                stickySession: true
            })]
        }
    }
)

export const handleError = onError((_, sentryEventId) => ({
    message: 'If this issue persists, please contact support. Thank you!',
    sentryEventId
}))